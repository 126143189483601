.Container {
	width: 100%;
    height: 100vh;
    flex-direction: column;
	align-items: center;
}

.Background-image {
	background-image: url('../../images/background-image.png');
	background-repeat: no-repeat;
	max-width: 1440px;
	height: 100vh;
	width: 100vw;
	display: inherit;
	flex-direction: column;
	align-items: center;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.creator-button {
	background-color: #1c87c9;
	-webkit-border-radius: 60px;
	border-radius: 60px;
	border: none;
	color: #eeeeee;
	cursor: pointer;
	display: inline-block;
	font-family: sans-serif;
	font-size: 20px;
	padding: 5px 15px;
	text-align: center;
	text-decoration: none;
	margin: 5px;
  }

  @keyframes glowing {
	0% {
	  background-color: #a80505;
	  box-shadow: 0 0 5px #a80505;
	}
	50% {
	  background-color: #7a0c0c;
	  box-shadow: 0 0 35px #720000b8;
	}
	100% {
	  background-color: #a80505;
	  box-shadow: 0 0 5px #a80505;
	}
  }

  /* @keyframes glowing {
	0% {
	  background-color: #c95400;
	  box-shadow: 0 0 5px #c95400;
	}
	50% {
	  background-color: #e82719;
	  box-shadow: 0 0 20px #e82719;
	}
	100% {
	  background-color: #c95400;
	  box-shadow: 0 0 5px #c95400;
	}
  } */

  .creator-button {
	animation: glowing 3000ms infinite;
  }
