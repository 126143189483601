@-webkit-keyframes autofill {
    0%,100% {
        color: #666;
        background: transparent;
    }
}

TextField:-webkit-autofill {
    -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}